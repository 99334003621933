// Base import
import React, { CSSProperties, HTMLAttributes } from 'react'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { hokkaidoTohoku, kanto, koshinetsuHokuriku, tokai, kansai, chugoku, shikoku, kyushu } from '../const/banks'

import Select from 'react-select'
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import { ValueContainerProps } from 'react-select/lib/components/containers'
import { ControlProps } from 'react-select/lib/components/Control'
import { MenuProps, NoticeProps } from 'react-select/lib/components/Menu'
import { OptionProps } from 'react-select/lib/components/Option'
import { PlaceholderProps } from 'react-select/lib/components/Placeholder'
import { SingleValueProps } from 'react-select/lib/components/SingleValue'
import { ValueType } from 'react-select/lib/types'

interface OptionType {
  label: string
  value: number
}

const suggestions: OptionType[] = 
hokkaidoTohoku.map(item => ({
  label: item.name,
  value: item.id,
})).concat(
  kanto.map(item => ({
    label: item.name,
    value: item.id,
  })),
  koshinetsuHokuriku.map(item => ({
    label: item.name,
    value: item.id,
  })),
  tokai.map(item => ({
    label: item.name,
    value: item.id,
  })),
  kansai.map(item => ({
    label: item.name,
    value: item.id,
  })),
  chugoku.map(item => ({
    label: item.name,
    value: item.id,
  })),
  shikoku.map(item => ({
    label: item.name,
    value: item.id,
  })),
  kyushu.map(item => ({
    label: item.name,
    value: item.id,
  }))
)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 50,
    },
    input: {
      display: 'flex',
      padding: 0,
      height: '3.3rem',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      backgroundColor: '#ffffff',
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
      marginLeft: '1rem',
      marginTop: '0.2rem',
      marginBottom: '0.2rem'
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: 'absolute',
      marginLeft: '0.4rem',
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
  }),
)

function NoOptionsMessage(props: NoticeProps<OptionType>) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      該当する銀行がありません
    </Typography>
  )
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />
}

function Control(props: ControlProps<OptionType>) {
  return (
    <TextField
      fullWidth
      variant="filled"
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.TextFieldProps}
    />
  )
}

function Option(props: OptionProps<OptionType>) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder(props: PlaceholderProps<OptionType>) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

function ValueContainer(props: ValueContainerProps<OptionType>) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

function Menu(props: MenuProps<OptionType>) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}
  
export default function SearchBankInput() {
  const classes = useStyles()
  const theme = useTheme()
  const [selected, setSelected] = React.useState<ValueType<OptionType>>(null)

  function handleChange(value: ValueType<OptionType>) {
    setSelected(value)
    if (value !== null && typeof value !== 'undefined') {
      const anchor = document.getElementById(value!['value'])
      const offsetTop = anchor!.getBoundingClientRect().top + window.pageYOffset
      window.scroll({
        top: offsetTop - 10,
        behavior: 'smooth'
      })
    }
  }

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  }

  return (
    <div className={classes.root}>
      <Select
        classes={classes}
        styles={selectStyles}
        options={suggestions}
        components={components}
        value={selected}
        onChange={handleChange}
        placeholder="紹介された銀行を検索"
        isClearable
      />
    </div>
  )
}
