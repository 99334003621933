export const hokkaidoTohoku = [
  {
    name: '北洋銀行',
    id: 1103,
  },
  {
    name: '北海道銀行',
    id: 1001,
  },
  {
    name: '青森銀行',
    id: 856,
  },
  {
    name: 'みちのく銀行',
    id: 858,
  },
  {
    name: '岩手銀行',
    id: 1101,
  },
  {
    name: '北日本銀行',
    id: 889,
  },
  {
    name: '東北銀行',
    id: 1351,
  },
  {
    name: '七十七銀行',
    id: 1395,
  },
  {
    name: '仙台銀行',
    id: 949,
  },
  {
    name: '秋田銀行',
    id: 877,
  },
  {
    name: '北都銀行',
    id: 6221,
  },
  {
    name: '荘内銀行',
    id: 6220,
  },
  {
    name: '山形銀行',
    id: 1128,
  },
  {
    name: '東邦銀行',
    id: 1348,
  }
]

export const kanto = [
  {
    name: '常陽銀行',
    id: 985,
  },
  {
    name: '筑波銀行',
    id: 787,
  },
  {
    name: '栃木銀行',
    id: 1214,
  },
  {
    name: '群馬銀行',
    id: 886,
  },
  {
    name: '武蔵野銀行',
    id: 563,
  },
  {
    name: '千葉興業銀行',
    id: 1014,
  },
  {
    name: '東日本銀行',
    imageName: '',
    id: 1347,
  },
  {
    name: '神奈川銀行',
    imageName: '',
    id: 883,
  },
  {
    name: '横浜銀行',
    imageName: '',
    id: 1276,
  },
]

export const koshinetsuHokuriku = [
  {
    name: '大光銀行',
    id: 882,
  },
  {
    name: '富山銀行',
    id: 1021,
  },
  {
    name: '北陸銀行',
    id: 1394,
  },
  {
    name: '北國銀行',
    id: 840,
  },
  {
    name: '福井銀行',
    id: 1267,
  },
  {
    name: '福邦銀行',
    id: 1043,
  },
  {
    name: '山梨中銀経営コンサルティング',
    id: 811
  },
  {
    name: '長野銀行',
    id: 1100,
  },
  {
    name: '八十二銀行',
    id: 1046,
  },
]

export const tokai = [
  {
    name: '十六銀行',
    id: 1069,
  },
  {
    name: '静岡銀行',
    id: 1378,
  },
  {
    name: '清水銀行',
    id: 1113,
  },
  {
    name: '中京銀行',
    id: 1305,
  },
]

export const kansai = [
  {
    name: '百五銀行',
    id: 1125,
  },
  {
    name: '滋賀銀行',
    id: 1161,
  },
  {
    name: '池田泉州銀行',
    imageName: '',
    id: 868,
  },
  {
    name: '但馬銀行',
    id: 2925,
  },
  {
    name: '南都銀行',
    id: 1321,
  },
]

export const chugoku = [
  {
    name: '鳥取銀行',
    id: 990,
  },
  {
    name: '山陰合同銀行',
    id: 1061,
  },
  {
    name: '中国銀行',
    id: 1972,
  },
  {
    name: '広島銀行',
    id: 1159,
  },
  {
    name: '西京銀行',
    id: 1391,
  }
]

export const shikoku = [
  {
    name: '阿波銀行',
    id: 578,
  },
  {
    name: '百十四銀行',
    id: 898,
  },
  {
    name: '伊予銀行',
    id: 1302,
  },
  {
    name: '愛媛銀行',
    id: 1173,
  },
  {
    name: '四国銀行',
    id: 938,
  },
]

export const kyushu = [
  {
    name: '西日本シティ銀行',
    id: 1329,
  },
  {
    name: '福岡中央銀行',
    id: 1592,
  },
  {
    name: '佐賀銀行',
    id: 1279,
  },
  {
    name: '十八銀行',
    id: 1220,
  },
  {
    name: '長崎銀行',
    id: 1723,
  },
  {
    name: '肥後銀行',
    id: 1030,
  },
  {
    name: '宮崎銀行',
    imageName: '',
    id: 803,
  },
  {
    name: '沖縄銀行',
    id: 1048,
  },
  {
    name: '沖縄海邦銀行',
    id: 947,
  },
  {
    name: '琉球銀行',
    id: 1080,
  },
]
