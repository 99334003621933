import { createMuiTheme } from '@material-ui/core/styles'
import orange from '@material-ui/core/colors/orange'
import deepPurple from '@material-ui/core/colors/deepPurple'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: orange[400],
    },
    secondary: {
      main: orange[50],
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: orange[600]
        }
      },
      focused: {}
    },
    MuiFilledInput: {
      underline: {
        '&:after': {
          borderBottom: `2px solid ${orange[600]}`
        }
      }
    }
  }
})

export default theme
